<template>
  <b-modal
    visible
    size="xl"
    centered
    v-model="showProfilePopup"
    hide-footer="true"
    hide-header
    hide-header-close
    :no-close-on-backdrop="true"
  >
    <div class="head">
      <span class="row">
        <h5 text>User Profile info</h5>
        <span class="space" @click="closePopUp">
          <img src="@/assets/images/icons/close.svg" alt="" />
        </span>
      </span>
    </div>
    <b-button class="input-group1-text" @click="downloadJson()">
      Download Json
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.37082 15.7988H12.4833C12.7179 15.7989 12.9438 15.888 13.1152 16.0482C13.2866 16.2084 13.3909 16.4276 13.4069 16.6617C13.4229 16.8958 13.3494 17.1272 13.2014 17.3092C13.0533 17.4912 12.8417 17.6102 12.6092 17.6422L12.4833 17.6509H1.37082C1.1362 17.6508 0.910348 17.5617 0.738906 17.4015C0.567464 17.2413 0.463215 17.022 0.447223 16.788C0.431231 16.5539 0.504689 16.3224 0.652753 16.1404C0.800817 15.9584 1.01245 15.8394 1.24488 15.8074L1.37082 15.7988H12.4833H1.37082ZM6.80111 0.990821L6.92705 0.982178C7.15083 0.982187 7.36704 1.06323 7.53569 1.21031C7.70433 1.3574 7.81402 1.56058 7.84445 1.78228L7.85309 1.90822V11.4007L10.6374 8.61767C10.7944 8.4607 11.0026 8.36538 11.224 8.34906C11.4454 8.33274 11.6654 8.39652 11.8437 8.52877L11.9474 8.61767C12.1044 8.7747 12.1997 8.98289 12.216 9.20431C12.2324 9.42574 12.1686 9.64566 12.0363 9.82399L11.9474 9.92771L7.58146 14.2924C7.42466 14.4493 7.21679 14.5446 6.99564 14.5612C6.77449 14.5777 6.55475 14.5143 6.37637 14.3826L6.27265 14.2924L1.90669 9.92771C1.741 9.76247 1.64401 9.54064 1.63522 9.3068C1.62642 9.07297 1.70648 8.84447 1.85929 8.66726C2.01211 8.49005 2.22634 8.37725 2.45893 8.35156C2.69152 8.32587 2.9252 8.38919 3.11301 8.52877L3.21673 8.61767L6.00102 11.4032V1.90822C6.00103 1.68444 6.08207 1.46823 6.22915 1.29959C6.37624 1.13094 6.57942 1.02125 6.80111 0.990821L6.92705 0.982178L6.80111 0.990821Z"
          fill="white"
        />
      </svg>
    </b-button>
    <div class="user-profile-data mt-3">
      <b-row>
        <b-col md="4">
          <b-list-group>
            <b-list-group-item class="d-flex align-items-center">
              <b-avatar
                variant="info"
                :src="userData.person.data.avatar"
                class="mr-3"
              ></b-avatar>
              <span class="mr-auto">{{ userData.person.data.name }}</span>
            </b-list-group-item>
            <b-list-group-item
              ><strong>JobTitle:</strong>
              {{ userData.person.data.position }}</b-list-group-item
            >
            <b-list-group-item
              ><strong>City:</strong>
              {{ userData.person.data.city }}
            </b-list-group-item>
            <b-list-group-item
              ><strong>Company:</strong>
              {{ userData.person.data.currentCompany.name }}
            </b-list-group-item>
            <b-list-group-item
              ><strong>Linkedin URL:</strong>
              {{ userData.person.data.currentCompany.link }}</b-list-group-item
            >
          </b-list-group>
        </b-col>

        <b-col md="8">
          <div class="accordion" role="tablist">
            <b-card
              no-body
              class="mb-1"
              v-if="
                userData &&
                  userData.person &&
                  userData.person.data &&
                  userData.person.data.experience &&
                  userData.person.data.experience.length > 0
              "
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-experience variant="info"
                  >Experience
                  <span v-if="!isExperienceExpanded">
                    <img
                      src="@/assets/images/icons/arrow-white-down.svg"
                      class="float-right"
                    />
                  </span>
                  <span v-if="isExperienceExpanded">
                    <img
                      src="@/assets/images/icons/arrow-up.svg"
                      class="float-right"
                    />
                  </span>
                  <!-- <i
                    v-if="!isExperienceExpanded"
                    class="fas fa-angle-double-down fa-md float-right"
                    title="Click to Expand"
                  ></i>
                  <i
                    v-if="isExperienceExpanded"
                    class="fas fa-angle-double-up fa-md float-right"
                    title="Click to Collapse"
                  ></i
                > -->
                </b-button>
              </b-card-header>
              <b-collapse
                v-model="isExperienceExpanded"
                id="accordion-experience"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      href="#"
                      class="flex-column align-items-start"
                      v-for="(element, index) in userData.person.data
                        .experience"
                      :key="index"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ element.title }}
                        </h5>
                      </div>

                      <small class="mr-4"
                        ><strong>Location:</strong>
                        {{ element.location }}</small
                      >
                      <small class="mr-4"
                        ><strong>Company:</strong>{{ element.company }}
                      </small>
                      <small
                        ><strong>Duration:</strong>{{ element.duration }}
                      </small>
                    </b-list-group-item>
                  </b-list-group>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-education variant="info"
                  >Education
                  <span v-if="!isEducationExpanded">
                    <img
                      src="@/assets/images/icons/arrow-white-down.svg"
                      class="float-right"
                    />
                  </span>
                  <span v-if="isEducationExpanded">
                    <img
                      src="@/assets/images/icons/arrow-up.svg"
                      class="float-right"
                    />
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse
                v-model="isEducationExpanded"
                id="accordion-education"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(element, index) in userData.person.data.education"
                      :key="index"
                      href="#"
                      class="flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">
                          {{ element.title }}
                        </h5>
                      </div>

                      <small class="mr-4"
                        ><strong>Subtitle: </strong>
                        {{ element.subtitle }}</small
                      >
                      <small class="mr-4"
                        ><strong>InstitutionName: </strong>
                        {{ element.institutionName }}</small
                      >
                      <small><strong>Meta: </strong> {{ element.meta }}</small>
                    </b-list-group-item>
                  </b-list-group>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-languages variant="info"
                  >Languages
                  <span v-if="!isLanguagesExpanded">
                    <img
                      src="@/assets/images/icons/arrow-white-down.svg"
                      class="float-right"
                    />
                  </span>
                  <span v-if="isLanguagesExpanded">
                    <img
                      src="@/assets/images/icons/arrow-up.svg"
                      class="float-right"
                    />
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse
                v-model="isLanguagesExpanded"
                id="accordion-languages"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(element, index) in userData.person.data.languages"
                      :key="index"
                      href="#"
                      class="flex-column align-items-start"
                    >
                      <h5>
                        {{ element.title }}
                        <b-badge>{{ element.subtitle }}</b-badge>
                      </h5>
                    </b-list-group-item>
                  </b-list-group>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
        <b-col class="mt-3" md="12">
          <h5>About</h5>
          <p>
            {{ userData.person.data.about }}
          </p>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "reveal-profile-popup",
  props: ["showProfilePopup", "userData"],
  data() {
    return {
      isExperienceExpanded: false,
      isEducationExpanded: false,
      isLanguagesExpanded: false,
    };
  },
  components: {},
  methods: {
    closePopUp: function() {
      this.showProfilePopup = false;
      this.$emit("closePopUp");
    },
    downloadJson() {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8, " +
          encodeURIComponent(JSON.stringify(this.userData))
      );
      element.setAttribute("download", "searchResult.txt");
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
<style>
.space {
  margin-left: auto;
}
.head {
  margin: 16px;
}
</style>
